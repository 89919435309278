import { FC, ReactElement } from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { resetDefis } from '../../../redux/action-creators/defis-actions-creators'
import { resetQuiz } from '../../../redux/action-creators/quiz-actions-creators'
import { resetUser } from '../../../redux/action-creators/users-actions-creators'
import { RootState } from '../../../redux/reducers'
import ButtonCogx from '../../button/button-cogx'

const TokenExpired: FC<any> = (props): ReactElement => {
  const dispatch = useDispatch()
  const state = useSelector((state: RootState) => state.user)

  function handleClose() {
    dispatch(resetUser())
    dispatch(resetQuiz())
    dispatch(resetDefis())
  }

  return (
    <Modal
      show={state.isTokenExpired}
      onHide={handleClose}
      animation={true}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>Session expirée</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Votre session est expirée , merci de vous reconnecter.
      </Modal.Body>
      <Modal.Footer>
        <ButtonCogx
          label="Ok"
          controller={handleClose}
          className="btn btn-primary btn-green"
        />
      </Modal.Footer>
    </Modal>
  )
}
export default TokenExpired
