import { combineReducers } from 'redux';
import defisReducers from './defisReducers';
import microVariableReducers from './microVariableReducers';
import quizReducers from './quizReducers';
import ressourcesReducers from './ressouresReducers';
import userReducers from './userReducers';

const reducers = combineReducers({
  user: userReducers,
  quiz: quizReducers,
  defis: defisReducers,
  ressources: ressourcesReducers,
  micro: microVariableReducers,
});

export default reducers;

export type RootState = ReturnType<typeof reducers>;
