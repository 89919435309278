import axios from 'axios';
import { toast } from 'react-toastify';
import { Dispatch } from 'redux';
import { mergeQuestions } from '../../services/question.service';
import { remove, setObjectStorage } from '../../services/storage-service';
import { QuizActionsTypes } from '../action-types/quiz-actions-types';

import { Action } from '../actions/quiz-actions';

export const changeIdMacroType = (id: Number) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: QuizActionsTypes.CHANGE_ID_MACRO_TYPE,
      payload: id,
    });
  };
};

export const resetQuiz = () => {
  remove('quiz-question');
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: QuizActionsTypes.RESET_QUIZ,
    });
  };
};

export const setResponseTemporaire = (data: any[]) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: QuizActionsTypes.SET_RESPONSE_TEMPORAIRE,
      payload: data,
    });
  };
};

export const setDefaultAnswerQuiz = (data: any) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: QuizActionsTypes.SET_DEFAULT_ANSWER_QUIZ,
      payload: data,
    });
  };
};

export const restartQuiz = () => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: QuizActionsTypes.RESTART_QUIZ,
    });
  };
};

export const changeStep = (step: number) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: QuizActionsTypes.CHANGE_STEP,
      payload: step,
    });
  };
};

export const getQuestionByQuestionnaireId = (
  id: string,
  isRestart: boolean
) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: QuizActionsTypes.GET_QUIZ_REQUEST,
    });
    await axios
      .get(
        `${process.env.REACT_APP_REST_API_LOCATION}/quiz-list/${id}?&isRestart=${isRestart}`
      )
      .then((data) => {
        if (data.data.data.attributes.results.length > 0) {
          dispatch({
            type: QuizActionsTypes.GET_QUIZ_SUCCESS,
            current_questions: mergeQuestions(
              data.data.data.attributes.results[0]?.questions
            ),
            payload: data.data.data.attributes.results[0],
            id_macrotype:
              data.data.data.attributes.results[0]?.questions[0]?.meta_variable
                .id,
          });

          data.data.data.attributes.results[0]?.id
            ? setObjectStorage(
                'quiz-question',
                data.data.data.attributes.results[0]
              )
            : remove('quiz-question');
        } else {
          dispatch({
            type: QuizActionsTypes.GET_QUIZ_SUCCESS,
            payload: data.data.data.attributes.results[0],
            id_macrotype: 0,
            current_questions: [],
          });
          // Les questionnaires sont désactiver pour le moment
          toast.warning(
            "Vous n'avez pas accès à ce questionnaire. Pour toute question, écrivez-nous à engram@cogx.fr",
            {
              position: 'bottom-right',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              progress: undefined,
            }
          );
        }
      })
      .catch((err) => {
        console.log('err', err);
        dispatch({
          type: QuizActionsTypes.GET_QUIZ_FAILED,
        });

        switch (err?.response?.status) {
          case 500:
            toast.error(
              "Erreur interne du Serveur , merci de bien vérifier votre url , si l'erreur persiste merci de contacter l'admin.",
              {
                position: 'bottom-right',
                autoClose: 9000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                progress: undefined,
              }
            );
            break;
          case 400:
            dispatch({
              type: QuizActionsTypes.SET_ACCESS_QUESTION,
              payload: false,
            });
            toast.warning(
              "Vous n'avez pas accès à ce questionnaire. Pour toute question, écrivez-nous à engram@cogx.fr",
              {
                position: 'bottom-right',
                autoClose: 9000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                progress: undefined,
              }
            );
            break;

          case 406:
            dispatch({
              type: QuizActionsTypes.SET_QUIZ_STATE,
              payload: false,
            });

            break;

          default:
          // code block
        }
      });
  };
};
