import axios from 'axios';
import { Store } from 'redux';
import { setIsTokenExpired } from '../redux/action-creators/users-actions-creators';

const setup = (store: Store) => {
  const { dispatch } = store;
  axios.interceptors.request.use(
    (config: any) => {
      if (store.getState().user.isAuthenticated) {
        config.headers['Authorization'] = `Bearer ${
          store.getState().user.user.jwt
        }`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.config;

      if (err?.response) {
        if (err?.response?.status === 401) {
          originalConfig._retry = true;

          try {
            dispatch(setIsTokenExpired(true) as any);
          } catch (_error) {
            return Promise.reject(_error);
          }
        }
      }

      return Promise.reject(err);
    }
  );
};

export default setup;
