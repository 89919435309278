import { FC, ReactElement } from 'react'
import { Modal } from 'react-bootstrap'
interface ConnexionProps {
  open: boolean
}
const NetWorkStatus: FC<ConnexionProps> = (props): ReactElement => {
  return (
    <Modal show={!props.open} animation={true} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>Internet</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Activez votre connexion internet pour que l'application fonctionne
        correctement
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  )
}
export default NetWorkStatus
