import axios from 'axios';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { Dispatch } from 'redux';
import { IDefis } from '../../models/defis';
import { randomArrayShuffle } from '../../services/global-service';
import { setObjectStorage } from '../../services/storage-service';
import { DefisActionsTypes } from '../action-types/defis-actions-types';
import { Action } from '../actions/defis-actions';

export const getDefisRequest = () => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: DefisActionsTypes.GET_DEFIS_REQUEST,
    });
  };
};

export const showInfoDefis = () => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: DefisActionsTypes.SHOW_DEFIS_INFO,
    });
  };
};

export const isDefisDone = () => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: DefisActionsTypes.IS_DEFIS_DONE,
    });
  };
};

export const isDefisCanceled = () => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: DefisActionsTypes.IS_DEFIS_CANCELED,
    });
  };
};

export const setDefisProgress = (id: number, from: string) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: DefisActionsTypes.DEFIS_PROGRESS,
      payload: id,
      from: from,
    });
  };
};

export const setDefisDoneOrCanceled = (id: number, typeRequest: string) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: DefisActionsTypes.SET_DEFIS_DONE_OR_CANCELED,
      payload: id,
      typeRequest: typeRequest,
    });
  };
};

export const resetDefis = () => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: DefisActionsTypes.RESET_DEFIS,
    });
  };
};

export const getDefisrSucces = (defis: IDefis, paginations: any) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: DefisActionsTypes.GET_DEFIS_SUCCESS,
      payload: defis,
      paginations: paginations,
    });
  };
};

export const getDefisFailed = () => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: DefisActionsTypes.GET_DEFIS_FAILED,
    });
  };
};

export const getDefisUser = (page?: number) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: DefisActionsTypes.GET_DEFIS_REQUEST,
    });
    document.body.classList.add('loading');
    await axios
      .get(`${process.env.REACT_APP_REST_API_LOCATION}/defis-user?page=${page}`)
      .then((data) => {
        data.data[3].data = _.uniqBy(data.data[3].data, 'id');
        if (data.data.length) {
          dispatch({
            type: DefisActionsTypes.GET_DEFIS_SUCCESS,
            payload: data.data,
            paginations: data?.data[2]?.pagination,
          });
          setObjectStorage('defis-temp', data.data);
          document.body.classList.remove('loading');
        }
      })
      .catch((err) => {
        console.log('errxx', err.response);
        document.body.classList.remove('loading');
        dispatch({
          type: DefisActionsTypes.GET_DEFIS_FAILED,
        });

        switch (err?.response?.data?.error?.status) {
          case 500:
            toast.error(
              "Erreur interne du Serveur , merci de bien vérifier votre url , si l'erreur persiste merci de contacter l'admin.",
              {
                position: 'bottom-right',
                autoClose: 9000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                progress: undefined,
              }
            );
            break;

          case 0:
            toast.error(
              "Il nous semble qu'il y a un problème avec votre connexion internet.",
              {
                position: 'bottom-right',
                autoClose: 9000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                progress: undefined,
              }
            );
            break;

          default:
          // code block
        }
      });
  };
};

export const finDefisUser = (page?: number, criteria?: number[]) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: DefisActionsTypes.GET_DEFIS_REQUEST,
    });
    await axios
      .get(
        `${
          process.env.REACT_APP_REST_API_LOCATION
        }/defis-user/${criteria?.toString()}?page=${page}`
      )
      .then((data) => {
        if (data.data.length) {
          data.data[0].data = randomArrayShuffle(data.data[0].data);

          dispatch({
            type: DefisActionsTypes.GET_DEFIS_SUCCESS,
            payload: data.data,
            paginations: data?.data[2]?.pagination,
          });
          setObjectStorage('defis-temp', data.data);
        }
      })
      .catch((err) => {
        console.log('errxx', err.response);
        dispatch({
          type: DefisActionsTypes.GET_DEFIS_FAILED,
        });

        switch (err?.response?.data?.error?.status) {
          case 500:
            toast.error(
              "Erreur interne du Serveur , merci de bien vérifier votre url , si l'erreur persiste merci de contacter l'admin.",
              {
                position: 'bottom-right',
                autoClose: 9000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                progress: undefined,
              }
            );
            break;

          case 0:
            toast.error(
              "Il nous semble qu'il y a un problème avec votre connexion internet.",
              {
                position: 'bottom-right',
                autoClose: 9000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                progress: undefined,
              }
            );
            break;

          default:
          // code block
        }
      });
  };
};
