import { getObjectLocal } from '../../services/storage-service';
import { RessourcesActionsTypes } from '../action-types/ressources-actions-types';
import { Action } from '../actions/ressources-actions';
const initialState = {
  isLoadingRessources: false,
  isErrorGetRessources: false,
  allRessources:
    getObjectLocal('ressources-temp').length > 0
      ? getObjectLocal('ressources-temp')
      : [],
  pagination: {},
} as any;

const reducer = (state = initialState, action: Action): any => {
  switch (action.type) {
    case RessourcesActionsTypes.GET_RESSOURCES_REQUEST:
      return {
        ...state,
        isLoadingRessources: true,
        isErrorGetRessources: false,
      };

    case RessourcesActionsTypes.GET_RESSOURCES_SUCCESS:
      return {
        ...state,
        isLoadingRessources: false,
        isErrorGetRessources: false,
        allRessources: action.payload,
        pagination: action.pagination,
      };

    case RessourcesActionsTypes.GET_RESSOURCES_FAILED:
      return { ...state, isLoadingDefis: false, isErrorGetRessources: true };

    case RessourcesActionsTypes.RESET_RESSOURCES:
      return {
        isLoadingRessources: false,
        isErrorGetRessources: false,
        allRessources: [],
        pagination: {},
      };

    default:
      return state;
  }
};

export default reducer;
