import { mergeQuestions } from '../../services/question.service';
import {
  getKeyLocal,
  getObjectLocal,
  getObjectSessionStorage,
} from '../../services/storage-service';
import { QuizActionsTypes } from '../action-types/quiz-actions-types';
import { Action } from '../actions/quiz-actions';

const initialState = {
  isLoadingQuiz: false,
  isErrorGetQuiz: false,
  allQuestion: getObjectLocal('quiz-question')
    ? getObjectLocal('quiz-question')
    : [],

  id_macrotype: getKeyLocal('quiz-question')
    ? getObjectLocal('quiz-question').questions[0]?.meta_variable?.id
    : 1,

  current_questions: getKeyLocal('quiz-question')
    ? mergeQuestions(getObjectLocal('quiz-question')?.questions)
    : [],

  current_step: 0,
  percentage: 0,
  isRestart: false,
  response_temporaire: getObjectLocal('user')?.jwt
    ? getObjectLocal('user').user.bilan
    : getObjectSessionStorage('user')?.jwt
    ? getObjectSessionStorage('user').user.bilan
    : [],

  defaultAnswerQuiz: [],
  haveAccess: true,
  isQuizCorrect: true,
} as any;

const reducer = (state = initialState, action: Action): any => {
  switch (action.type) {
    case QuizActionsTypes.GET_QUIZ_REQUEST:
      return {
        ...state,
        isLoadingQuiz: true,
        isErrorGetQuiz: false,
        haveAccess: true,
        isQuizCorrect: true,
      };

    case QuizActionsTypes.RESTART_QUIZ:
      return {
        ...state,
        isRestart: !state.isRestart,
      };

    case QuizActionsTypes.SET_QUIZ_STATE:
      return {
        ...state,
        isQuizCorrect: false,
      };

    case QuizActionsTypes.GET_QUIZ_SUCCESS:
      return {
        ...state,
        isLoadingQuiz: false,
        isErrorGetQuiz: false,
        allQuestion: action.payload,
        id_macrotype: action.id_macrotype,
        current_questions: action.current_questions,
      };

    case QuizActionsTypes.GET_QUIZ_FAILED:
      return { ...state, isLoadingQuiz: false, isErrorGetQuiz: true };

    case QuizActionsTypes.SET_RESPONSE_TEMPORAIRE:
      return { ...state, response_temporaire: action.payload };

    case QuizActionsTypes.SET_DEFAULT_ANSWER_QUIZ:
      return {
        ...state,
        defaultAnswerQuiz:
          action.payload.length > 0
            ? action.payload.sort(
                (a: any, b: any) => a?.question_id - b?.question_id
              )
            : [],
        current_step: action.payload.length > 0 ? action.payload.length - 1 : 0,
      };

    case QuizActionsTypes.CHANGE_STEP:
      return { ...state, current_step: action.payload };

    case QuizActionsTypes.SET_ACCESS_QUESTION:
      return { ...state, haveAccess: action.payload };

    case QuizActionsTypes.CHANGE_ID_MACRO_TYPE:
      return {
        ...state,
        id_macrotype: action.payload,
      };

    case QuizActionsTypes.RESET_QUIZ:
      return {
        isLoadingQuiz: false,
        isErrorGetQuiz: false,
        allQuestion: [],
        id_macrotype: 1,
        current_questions: [],
        current_step: 0,
        haveAccess: true,
        percentage: 0,
        isRestart: false,
        defaultAnswerQuiz: [],
        response_temporaire: [],
        isQuizCorrect: true,
      };

    default:
      return state;
  }
};

export default reducer;
