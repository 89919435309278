import React, { FC, ReactElement } from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { resetDefis } from '../../../redux/action-creators/defis-actions-creators'
import { resetAllMicroVariables } from '../../../redux/action-creators/micro-variable-actions-creators'
import { resetQuiz } from '../../../redux/action-creators/quiz-actions-creators'
import { resetRessources } from '../../../redux/action-creators/ressources-actions-creators'
import {
  resetUser,
  setIsLogOut,
} from '../../../redux/action-creators/users-actions-creators'
import { RootState } from '../../../redux/reducers'
import ButtonCogx from '../../button/button-cogx'
import './logout.scss'

const Logout: FC<any> = (): ReactElement => {
  const dispatch = useDispatch()
  const state = useSelector((state: RootState) => state.user)
  const navaBarRefs = React.useRef<any>(null)
  function handleClose() {
    dispatch(resetUser())
    dispatch(resetQuiz())
    dispatch(resetDefis())
    dispatch(resetRessources())
    dispatch(resetAllMicroVariables())
  }

  function handleCloseNo() {
    if (navaBarRefs.current) {
      navaBarRefs.current.click()
    }
    dispatch(setIsLogOut(false))
  }

  document.body.classList.remove('modal-dialog')
  return (
    <Modal show={state.isLogout} onHide={handleCloseNo} animation={true}>
      <Modal.Header closeButton>
        <Modal.Title>Déconnexion</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Êtes-vous sûr·e de vous vouloir vous déconnecter ?
      </Modal.Body>
      <Modal.Footer className="footer-logout">
        <ButtonCogx
          label="Oui"
          controller={handleClose}
          className="btn btn-secondary"
        />
        <ButtonCogx
          label="Non"
          controller={handleCloseNo}
          className="btn btn-primary btn-green"
        />
      </Modal.Footer>
    </Modal>
  )
}
export default Logout
