export enum QuizActionsTypes {
  GET_QUIZ_SUCCESS = 'GET_QUIZ_SUCCESS',
  GET_QUIZ_FAILED = 'GET_QUIZ_FAILED',
  GET_QUIZ_REQUEST = 'GET_QUIZ_REQUEST',
  CHANGE_ID_MACRO_TYPE = 'CHANGE_ID_MACRO_TYPE',
  RESET_QUIZ = 'RESET_QUIZ',
  SET_ACCESS_QUESTION = 'SET_ACCESS_QUESTION',
  CHANGE_STEP = 'CHANGE_STEP',
  SET_RESPONSE_TEMPORAIRE = 'SET_RESPONSE_TEMPORAIRE',
  RESTART_QUIZ = 'RESTART_QUIZ',
  SET_DEFAULT_ANSWER_QUIZ = 'SET_DEFAULT_ANSWER_QUIZ',
  SET_QUIZ_STATE = 'SET_QUIZ_STATE',
}
