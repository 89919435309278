export enum DefisActionsTypes {
  GET_DEFIS_SUCCESS = 'GET_DEFIS_SUCCESS',
  GET_DEFIS_FAILED = 'GET_DEFIS_FAILED',
  GET_DEFIS_REQUEST = 'GET_DEFIS_REQUEST',
  RESET_DEFIS = 'RESET_DEFIS',
  SHOW_DEFIS_INFO = 'SHOW_DEFIS_INFO',
  DEFIS_PROGRESS = 'DEFIS_PROGRESS',
  DEFIS_CANCELED = 'DEFIS_CANCELED',
  IS_DEFIS_DONE = 'IS_DEFIS_DONE',
  IS_DEFIS_CANCELED = 'IS_DEFIS_CANCELED',
  SET_DEFIS_DONE_OR_CANCELED = 'SET_DEFIS_DONE_OR_CANCELED',
  IS_SHOW_FILTER = 'IS_SHOW_FILTER',
}
