import {
  getObjectLocal,
  setObjectStorage,
} from '../../services/storage-service';
import { DefisActionsTypes } from '../action-types/defis-actions-types';
import { Action } from '../actions/defis-actions';
const initialState = {
  isLoadingDefis: true,
  isErrorGetDefis: false,
  allDefis:
    getObjectLocal('defis-temp').length > 0 ? getObjectLocal('defis-temp') : [],
  pagination: {},
  isShowDefis: false,
  isDefisDone: false,
  isDefiCanceled: false,
  isFilter: false, // This is the same STATE that we will use to display the resource filter because we are using the same component
} as any;

function updateDefisProgress(data: any, id: number, from: string) {
  const indexAllDefis = from === 'for_me' ? 0 : 2;
  let new_data_for_me;
  let new_data_progress;

  switch (indexAllDefis) {
    case 0:
      new_data_for_me = data[indexAllDefis].data.filter((res: any) => {
        return res?.id !== id;
      });

      new_data_progress = data[indexAllDefis].data.filter((res: any) => {
        return res?.id === id;
      });
      new_data_progress[0].defisProgress = true;
      let update = [
        {
          title: 'Les défis sélectionnés pour moi',
          data: new_data_for_me,
        },
        {
          title: 'Mes défis en cours',
          data: data[1].data.concat(new_data_progress),
        },
        {
          title: 'Mes autres défis disponibles',
          data: data[2].data,
        },
      ];
      setObjectStorage('defis-temp', update as any);

      return update;

    case 2:
      new_data_for_me = data[indexAllDefis].data.filter((res: any) => {
        return res?.id !== id;
      });

      new_data_progress = data[indexAllDefis].data.filter((res: any) => {
        return res?.id === id;
      });
      new_data_progress[0].defisProgress = true;
      let update_two = [
        {
          title: 'Les défis sélectionnés pour moi',
          data: data[0].data,
        },
        {
          title: 'Mes défis en cours',
          data: data[1].data.concat(new_data_progress),
        },
        {
          title: 'Mes autres défis disponibles',
          data: new_data_for_me,
        },
      ];
      setObjectStorage('defis-temp', update_two as any);

      return update_two;

    default:
      return data;
  }
}

function updateDefisDoneOrCanceled(data: any, id: number, typeRequest: string) {
  let new_data_for_me;
  let new_data_progress;

  new_data_for_me = data[1].data.filter((res: any) => {
    return res?.id !== id;
  });

  console.log('dataforme', data);
  new_data_progress = data[1].data.filter((res: any) => {
    return res?.id === id;
  });

  if (typeRequest === 'canceled') {
    new_data_progress[0].defisProgress = false;
    new_data_progress[0].defisCancelled = true;
    new_data_progress[0].defisDone = false;
  } else {
    new_data_progress[0].defisProgress = false;
    new_data_progress[0].defisCancelled = false;
    new_data_progress[0].defisDone = true;
  }

  let update = [
    {
      title: 'Les défis sélectionnés pour moi',
      data: data[0].data,
    },
    {
      title: 'Mes défis en cours',
      data: new_data_for_me,
    },
    {
      title: 'Mes autres défis disponibles',
      data: data[2].data.concat(new_data_progress),
    },
  ];
  setObjectStorage('defis-temp', update as any);
  return update;
}

const reducer = (state = initialState, action: Action): any => {
  switch (action.type) {
    case DefisActionsTypes.GET_DEFIS_REQUEST:
      return {
        ...state,
        isLoadingDefis: true,
        isErrorGetDefis: false,
      };

    case DefisActionsTypes.SHOW_DEFIS_INFO:
      return {
        ...state,
        isShowDefis: !state.isShowDefis,
      };

    case DefisActionsTypes.IS_DEFIS_CANCELED:
      return {
        ...state,
        isDefiCanceled: !state.isDefiCanceled,
      };

    case DefisActionsTypes.IS_SHOW_FILTER:
      return {
        ...state,
        isFilter: !state.isFilter,
      };

    case DefisActionsTypes.IS_DEFIS_DONE:
      return {
        ...state,
        isDefisDone: !state.isDefisDone,
      };

    case DefisActionsTypes.GET_DEFIS_SUCCESS:
      return {
        ...state,
        isLoadingDefis: false,
        isErrorGetDefis: false,
        allDefis: action.payload,
        pagination: action.paginations,
      };

    case DefisActionsTypes.GET_DEFIS_FAILED:
      return { ...state, isLoadingDefis: false, isErrorGetDefis: true };

    case DefisActionsTypes.DEFIS_PROGRESS:
      return {
        ...state,
        allDefis: updateDefisProgress(
          state.allDefis,
          action.payload,
          action.from
        ),
      };

    case DefisActionsTypes.SET_DEFIS_DONE_OR_CANCELED:
      return {
        ...state,
        allDefis: updateDefisDoneOrCanceled(
          state.allDefis,
          action.payload,
          action.typeRequest
        ),
      };

    case DefisActionsTypes.RESET_DEFIS:
      return {
        isLoadingDefis: false,
        isErrorGetDefis: false,
        allDefis: [],
        pagination: {},
        isShowDefis: false,
        isDefisDone: false,
        isDefiCanceled: false,
        isFilter: false,
      };

    default:
      return state;
  }
};

export default reducer;
