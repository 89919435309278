import { MicroVariableActionsTypes } from '../action-types/micro-variable-actions-types';
import { Action } from '../actions/micro-variable-actions';
const initialState = {
  isLoadingMicro: false,
  allMicroVariables: [],
  errorGetMicro: false,
  showMicroVariableList: false,
  microVariableFilterDefis: [],
  microVariableFilterRessources: [],
} as any;

const reducer = (state = initialState, action: Action): any => {
  switch (action.type) {
    case MicroVariableActionsTypes.GET_MICRO_VARIABLES_REQUEST:
      return {
        ...state,
        isLoadingMicro: true,
        errorGetMicro: false,
        // showMicroVariableList: true,
      };

    case MicroVariableActionsTypes.GET_MICRO_VARIABLES_SUCCESS:
      return {
        ...state,
        isLoadingMicro: false,
        errorGetMicro: false,
        allMicroVariables: action.payload,
        //   showMicroVariableList: true,
      };

    case MicroVariableActionsTypes.GET_MICRO_VARIABLES_FAILED:
      return {
        ...state,
        isLoadingMicro: false,
        errorGetMicro: true,
      };

    case MicroVariableActionsTypes.SHOW_FILTER_MICRO_VARIABLES:
      return {
        ...state,
        showMicroVariableList: !state.showMicroVariableList,
      };

    case MicroVariableActionsTypes.UPDATE_MICRO_VARIABLE_FILTER_DEFIS:
      return {
        ...state,
        microVariableFilterDefis: action.payload,
      };

    case MicroVariableActionsTypes.UPDATE_MICRO_VARIABLE_FILTER_RESSOURCES:
      return {
        ...state,
        microVariableFilterRessources: action.payload,
      };

    case MicroVariableActionsTypes.RESET_MICRO_VARIABLES:
      return {
        isLoadingMicro: false,
        allMicroVariables: [],
        errorGetMicro: false,
        showMicroVariableList: false,
        microVariableFilterDefis: [],
        microVariableFilterRessources: [],
      };

    default:
      return state;
  }
};

export default reducer;
