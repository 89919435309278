import axios from 'axios';
import { toast } from 'react-toastify';
import { Dispatch } from 'redux';
import { setObjectStorage } from '../../services/storage-service';
import { RessourcesActionsTypes } from '../action-types/ressources-actions-types';
import { Action } from '../actions/ressources-actions';

export const resetRessources = () => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: RessourcesActionsTypes.RESET_RESSOURCES,
    });
  };
};

export const getRessourcesUser = (page?: number) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: RessourcesActionsTypes.GET_RESSOURCES_REQUEST,
    });
    await axios
      .get(
        `${process.env.REACT_APP_REST_API_LOCATION}/ressources-user?page=${page}`
      )
      .then((data) => {
        if (data.data.length) {
          dispatch({
            type: RessourcesActionsTypes.GET_RESSOURCES_SUCCESS,
            payload: data.data,
            pagination: data.data[1].pagination,
          });
          setObjectStorage('ressources-temp', data.data);
        }
      })
      .catch((err) => {
        console.log('err', err);
        dispatch({
          type: RessourcesActionsTypes.GET_RESSOURCES_FAILED,
        });

        switch (err?.response?.data?.error?.status) {
          case 500:
            toast.error(
              "Erreur interne du Serveur , merci de bien vérifier votre url , si l'erreur persiste merci de contacter l'admin.",
              {
                position: 'bottom-right',
                autoClose: 9000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                progress: undefined,
              }
            );
            break;

          default:
          // code block
        }
      });
  };
};

export const getRessourcesUserFind = (page?: number, criteria?: number[]) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: RessourcesActionsTypes.GET_RESSOURCES_REQUEST,
    });
    await axios
      .get(
        `${
          process.env.REACT_APP_REST_API_LOCATION
        }/ressources-user/${criteria?.toString()}?page=${page}`
      )
      .then((data) => {
        if (data.data.length) {
          dispatch({
            type: RessourcesActionsTypes.GET_RESSOURCES_SUCCESS,
            payload: data.data,
            pagination: data.data[1].pagination,
          });
          setObjectStorage('ressources-temp', data.data);
        }
      })
      .catch((err) => {
        console.log('err', err);
        dispatch({
          type: RessourcesActionsTypes.GET_RESSOURCES_FAILED,
        });

        switch (err?.response?.data?.error?.status) {
          case 500:
            toast.error(
              "Erreur interne du Serveur , merci de bien vérifier votre url , si l'erreur persiste merci de contacter l'admin.",
              {
                position: 'bottom-right',
                autoClose: 9000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                progress: undefined,
              }
            );
            break;

          default:
          // code block
        }
      });
  };
};
