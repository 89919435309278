import React, { ForwardRefRenderFunction, InputHTMLAttributes } from 'react'
import './button-cogx.scss'
interface ButtonProps extends InputHTMLAttributes<HTMLInputElement> {
  name?: string
  label: string
  className: string
  disabled?: boolean
  controller: (data?: any) => void
}

const ButtonCogx: ForwardRefRenderFunction<HTMLInputElement, ButtonProps> = (
  { label, className, value, disabled, placeholder, controller },
  ref,
) => {
  return (
    <button
      className={className}
      placeholder={placeholder}
      disabled={disabled}
      value={value}
      onClick={controller}
    >
      {label}
    </button>
  )
}

const FormInput = React.forwardRef(ButtonCogx)

export default FormInput
