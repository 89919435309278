export function setKey(key: string, value: string) {
  return new Promise((resolve) => {
    window.localStorage.setItem(key, value);
    resolve(value);
  });
}

export function setObjectStorage(key: string, value: any) {
  return new Promise((resolve) => {
    window.localStorage.setItem(key, JSON.stringify(value));
    resolve(value);
  });
}

export function setKeyLocal(key: string, value: string) {
  window.localStorage.setItem(key, value);
}
export function remove(key: string) {
  window.localStorage.removeItem(key);
}
export function getKey(key: string) {
  return new Promise((resolve) => {
    resolve(window.localStorage.getItem(key));
  });
}

export function getObjectLocal(key: string) {
  return JSON.parse(window.localStorage.getItem(key) || '{}');
}

export function getKeyLocal(key: string) {
  return window.localStorage.getItem(key);
}

export function setSession(key: string, value: string) {
  sessionStorage.setItem(key, value);
}

export function setObjectSessionStorage(key: string, value: string) {
  return new Promise((resolve) => {
    sessionStorage.setItem(key, JSON.stringify(value));
    resolve(value);
  });
}

export function getObjectSessionStorage(key: string) {
  return JSON.parse(sessionStorage.getItem(key) || '[]');
}

export function getSessionLocal(key: string) {
  return sessionStorage.getItem(key);
}
export function clearLocal() {
  return new Promise<void>((resolve, reject) => {
    window.localStorage.clear();
    resolve();
  });
}

export function delSession(key: string) {
  return new Promise<void>((resolve, reject) => {
    window.sessionStorage.removeItem(key);

    resolve();
  });
}
export function clearSession() {
  return new Promise<void>((resolve, reject) => {
    window.sessionStorage.clear();
    resolve();
  });
}
