import axios from 'axios';
export function goToTop() {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
}
export function randomArrayShuffle(array: any) {
  return array.sort(() => Math.random() - 0.5);
}

export function formateDate(daty: any) {
  let d = new Date(daty);
  const year = d.getFullYear();
  const month = String(d.getMonth() + 1).padStart(2, '0');
  const date = String(d.getDate()).padStart(2, '0');
  const hr = String(d.getHours()).padStart(2, '0');
  const min = String(d.getMinutes()).padStart(2, '0');
  // const sec = String(d.getSeconds()).padStart(2, '0');

  return `${date}/${month}/${year} - ${hr}h:${min}`;
}

export function getDateOnly(daty: any) {
  let d = new Date(daty);
  const year = d.getFullYear();
  const month = String(d.getMonth() + 1).padStart(2, '0');
  const date = String(d.getDate()).padStart(2, '0');

  return `${date}/${month}/${year}`;
}

export function getHourOnly(daty: any) {
  let d = new Date(daty);

  const hr = String(d.getHours()).padStart(2, '0');
  const min = String(d.getMinutes()).padStart(2, '0');
  // const sec = String(d.getSeconds()).padStart(2, '0');

  return `${hr}h:${min}`;
}

export async function getCgu() {
  return await axios.get(
    `${process.env.REACT_APP_REST_API_LOCATION}/legale-notice?populate=*`
  );
}

export async function svgChange(url: string, color: string) {
  return fetch(url)
    .then((response: any) => response.text())
    .then((svg: any) => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(svg, 'image/svg+xml');
      const svgEl = doc.querySelector('svg'); // Sélectionne la balise SVG
      const pathEl = doc.querySelector('path'); // Sélectionne la balise path

      if (pathEl) {
        pathEl.setAttribute('fill', color); // Applique la couleur passée en paramètre au fill de la balise path
      }

      return svgEl?.outerHTML; // Retourne le code HTML de la balise SVG
    })
    .catch((error) => console.error(error));
}

export function generateFilterString(color: string): string {
  const rgb = color
    .match(/[A-Za-z0-9]{2}/g)
    ?.map((value) => parseInt(value, 16)) ?? [0, 0, 0];

  const [r, g, b] = rgb;
  const invert = 100 - r / 2.55;
  const sepia = g / 2.55;
  const saturate = Math.max(r, g, b) * 3.5;
  const hueRotate = 123;
  const brightness = Math.max(r, g, b) / 2.55;
  const contrast = Math.max(r, g, b) * 0.92;

  return `invert(${invert}%) sepia(${sepia}%) saturate(${saturate}%) hue-rotate(${hueRotate}deg) brightness(${brightness}%) contrast(${contrast}%)`;
}

export function convertHexToCSS(hexColor: any): string {
  // Extraire les valeurs R, G et B de la couleur hexadécimale
  const r = parseInt(hexColor.substring(1, 3), 16);
  const g = parseInt(hexColor.substring(3, 5), 16);
  const b = parseInt(hexColor.substring(5, 7), 16);

  // Calculer les paramètres de filtre CSS à partir des valeurs R, G et B
  const invert = Math.round(((255 - r) / 255) * 100);
  const sepia = Math.round((g / 255) * 100);
  const saturate = Math.round((Math.max(r, g, b) / 255) * 3777);
  const hueRotate = Math.round((r / 255) * 360);
  const brightness = Math.round((Math.min(r, g, b) / 255) * 100 + 5);
  const contrast = Math.round((Math.max(r, g, b) / Math.min(r, g, b)) * 100);

  // Concaténer les paramètres de filtre CSS en une chaîne CSS `filter`
  return `invert(${invert}%) sepia(${sepia}%) saturate(${saturate}%) hue-rotate(${hueRotate}deg) brightness(${brightness}%) contrast(${contrast}%)`;
}

export function modifierSVG(couleur: string, transparence: number): string {
  // Générer la chaîne de style CSS en fonction des valeurs fournies
  const style = `${couleur}; opacity: ${transparence}`;

  // Retourner la chaîne de style en tant que valeur de la propriété "style" de l'élément <img>
  return style;
}

export function hexToRgba(hex: string, opacity: number): string {
  if (hex && opacity) {
    const hexValue = hex.replace('#', '');
    const red = parseInt(hexValue.substring(0, 2), 16);
    const green = parseInt(hexValue.substring(2, 4), 16);
    const blue = parseInt(hexValue.substring(4, 6), 16);
    return `rgba(${red}, ${green}, ${blue}, ${opacity})`;
  }
  return '';
}
export function lightenColor(hexColor: any, amount: any) {
  // On assure que le format de couleur est correct en supprimant les caractères non hexadécimaux

  if (hexColor && amount) {
    const cleanHexColor = hexColor?.replace(/[^0-9a-fA-F]/g, '');

    // On assure que le format de couleur a exactement 6 chiffres
    if (cleanHexColor.length !== 6) {
      throw new Error('Le code hexadécimal doit avoir exactement 6 chiffres.');
    }

    // On convertit les valeurs hexadécimales en décimal
    const r = parseInt(cleanHexColor?.substring(0, 2), 16);
    const g = parseInt(cleanHexColor?.substring(2, 4), 16);
    const b = parseInt(cleanHexColor?.substring(4, 6), 16);

    // On ajuste la luminosité en ajoutant un montant à chaque composant RGB
    const lightenR = Math.min(255, r + amount);
    const lightenG = Math.min(255, g + amount);
    const lightenB = Math.min(255, b + amount);

    // On convertit les valeurs RGB ajustées en code hexadécimal
    const lightenHexColor = `#${componentToHex(lightenR)}${componentToHex(
      lightenG
    )}${componentToHex(lightenB)}`;

    return lightenHexColor;
  }
}

export function componentToHex(c: any) {
  const hex = c.toString(16);
  return hex.length === 1 ? `0${hex}` : hex;
}
