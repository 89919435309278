import axios from 'axios';
import { toast } from 'react-toastify';
import { Dispatch } from 'redux';

import { MicroVariableActionsTypes } from '../action-types/micro-variable-actions-types';
import { Action } from '../actions/micro-variable-actions';

export const getMicroVariableRequest = () => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: MicroVariableActionsTypes.GET_MICRO_VARIABLES_REQUEST,
    });
  };
};

export const getMicroVariableRequestFailed = () => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: MicroVariableActionsTypes.GET_MICRO_VARIABLES_FAILED,
    });
  };
};

export const showFilterMicro = () => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: MicroVariableActionsTypes.SHOW_FILTER_MICRO_VARIABLES,
    });
  };
};

export const resetAllMicroVariables = () => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: MicroVariableActionsTypes.RESET_MICRO_VARIABLES,
    });
  };
};

function orderAlphabetiqueArrayObject(data: any) {
  const sortedList = data.sort((a: any, b: any) =>
    a.name.localeCompare(b.name)
  );
  return sortedList;
}

export const getListMicroVariable = (page?: number) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: MicroVariableActionsTypes.GET_MICRO_VARIABLES_REQUEST,
    });
    await axios
      .get(
        `${process.env.REACT_APP_REST_API_LOCATION}/micro-variable/list?page=${page}`
      )
      .then((data) => {
        if (data.data.length > 0) {
          dispatch({
            type: MicroVariableActionsTypes.GET_MICRO_VARIABLES_SUCCESS,
            payload: orderAlphabetiqueArrayObject(data?.data),
            paginations: data?.data[2]?.pagination,
          });
        }
      })
      .catch((err) => {
        console.log('errxx', err.response);
        dispatch({
          type: MicroVariableActionsTypes.GET_MICRO_VARIABLES_FAILED,
        });

        switch (err?.response?.data?.error?.status) {
          case 500:
            toast.error(
              "Erreur interne du Serveur , merci de bien vérifier votre url , si l'erreur persiste merci de contacter l'admin.",
              {
                position: 'bottom-right',
                autoClose: 9000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                progress: undefined,
              }
            );
            break;

          case 0:
            toast.error(
              "Il nous semble qu'il y a un problème avec votre connexion internet.",
              {
                position: 'bottom-right',
                autoClose: 9000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                progress: undefined,
              }
            );
            break;

          default:
          // code block
        }
      });
  };
};

export const updateMicroVariableFilterDefis = (data: number[]) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: MicroVariableActionsTypes.UPDATE_MICRO_VARIABLE_FILTER_DEFIS,
      payload: data,
    });
  };
};

export const updateMicroVariableFilterRessources = (data: number[]) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: MicroVariableActionsTypes.UPDATE_MICRO_VARIABLE_FILTER_RESSOURCES,
      payload: data,
    });
  };
};
